const setCookie = (name, value, days) => {
  if (typeof window === 'undefined') {
    return
  }

  const domain = window.location.hostname;
  const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1e3
  let expires = ''
  if (typeof days === 'number') {
    let date = new Date()
    date.setTime(date.getTime() + days * MILLISECONDS_PER_DAY)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + value + expires + '; path=/; domain=' + domain
}

const getCookie = c_name => {
  if (typeof window === 'undefined') {
    return ''
  }

  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=')
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1
      let c_end = document.cookie.indexOf(';', c_start)
      if (c_end == -1) {
        c_end = document.cookie.length
      }
      return decodeURIComponent(document.cookie.substring(c_start, c_end))
    }
  }
  return ''
}

export {
  setCookie,
  getCookie
}