exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-academy-article-js": () => import("./../../../src/templates/academy-article.js" /* webpackChunkName: "component---src-templates-academy-article-js" */),
  "component---src-templates-academy-js": () => import("./../../../src/templates/academy.js" /* webpackChunkName: "component---src-templates-academy-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-life-blog-js": () => import("./../../../src/templates/life-blog.js" /* webpackChunkName: "component---src-templates-life-blog-js" */),
  "component---src-templates-life-blog-post-js": () => import("./../../../src/templates/life-blog-post.js" /* webpackChunkName: "component---src-templates-life-blog-post-js" */),
  "component---src-templates-regular-js": () => import("./../../../src/templates/regular.js" /* webpackChunkName: "component---src-templates-regular-js" */)
}

