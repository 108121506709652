import siteConfig from '../data/config/site-config.json';

interface Hop {
  url: string;
  method: string;
  cache?: RequestCache;
  credentials?: RequestCredentials;
  mode?: RequestMode;
  headers?: any;
  jsonBody?: any;
  isBlind?: boolean;
}

export const hop = async ({
  url,
  method,
  mode = 'cors',
  cache = 'default',
  credentials = 'omit',
  headers: customHeaders,
  jsonBody,
  isBlind,
}: Hop) => {
  let body = undefined;

  const pkAll = cookiesToPkAll();
  const headers = new Headers({
    ...customHeaders
  });

  if (method !== 'GET') {
    headers.append('Content-Type', 'application/json');
    body = JSON.stringify({
      ...jsonBody,
      ...pkAll,
    });
  }

  try {
    const response = await fetch(url, {
      method,
      mode,
      cache,
      credentials,
      headers,
      body,
    });

    if (!response.ok) {
      return false;
    }

    if (isBlind) {
      return true;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return false;
  }
};

export const putRequest = async (url: string, jsonBody: any) =>
  hop({ url, method: 'PUT', jsonBody });

export const getRequest = async (url: string, type: string | null = null) =>
  hop({ url, method: 'GET', isBlind: type === 'blind' });

export const blindPostRequest = async (url: string, jsonBody: any) =>
  hop({ url, method: 'POST', jsonBody, isBlind: true });

export const blindGetRequest = async (url: string) =>
  hop({ url, method: 'GET', isBlind: true });

const cookiesToPkAll = () => {
  const allCookies = document.cookie.split('; ');
  const pkCookies = allCookies.filter(cookie => {
    const [cookieName] = cookie.split('=');
    if (!cookieName) {
      return false;
    }
    return siteConfig.analytics_pks.some(key => cookieName.includes(key));
  });
  const pkCookiesString = pkCookies.join('; ');

  if (pkCookiesString.length > 0) {
    const modifiedBody = {
      PkAll: pkCookiesString,
    };
    return modifiedBody;
  }

  return {
    PkAll: '',
  };
};
