import { getCookie, setCookie } from './cookie-handler'
import { blindPostRequest } from './network'
import siteConfig from '../data/config/site-config.json'

declare global {
  interface Window {
    posthog?: any;
  }
}

/**
 * Posthog script lives (or must live) in the head of html document.
 * This should mean that it is accessible via window object.
 * */
let posthog;

const COOKIE_EXPIRY_DAYS = 360 as const
const POSTHOG_REQUEST_MILLIS = 1000 as const

/**
 * Data we want to track
 */
const paramsToTrack = siteConfig.analytics_pks.reduce((acc, curr) => {
  return (acc[curr] = null, acc)
}, {})

/**
 * Get the value of a URL parameter
 * @param name URL parameter to check
 * @returns parameter string value or empty string
 */
const getParam = (name) => {
  if (typeof window !== 'undefined') {
    const i = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search)
    const theParam = i ? decodeURIComponent(i[1].replace(/\+/g, ' ')) : ''
    return theParam
  }
  return '';
}

/**
 * Check if parameter value is valid
 * @param value parameter to check
 * @returns boolean
 */
const isUrlParamValid = (value) => typeof value === 'string' && value.length > 0

/**
 * Extract tracking data form url. If data property is not
 * found in url, try looking for it in cookies.
 */
const extractURLParamsToTrack = () =>
  new Promise<void>((resolve, reject) => {
    Object.keys(paramsToTrack).forEach((name) => {
      // check for a value in params
      let value = getParam(name)
      if (isUrlParamValid(value)) {
        // and save it to a cookie
        setCookie(name, value, COOKIE_EXPIRY_DAYS)
      } else {
        // otherwise try reading it from cookies
        value = getCookie(name)
      }

      if (isUrlParamValid(value)) {
        // also save to our params object for later use
        paramsToTrack[name] = value
      }
    })

    resolve()
  })

const setRefAndAffiliateCookies = () =>
  new Promise<void>((resolve, reject) => {
    // set some more cookies (reason assumed / unknown)
    const referrer = `${document.referrer}`
    if (!referrer.includes('bunny.net/')) {
      setCookie('ref_domain', referrer, 360)
    }

    // trigger an endpoint (reason assumed / unknown)
    const ref = getParam('ref')
    if (ref !== null && isUrlParamValid(ref)) {
      fetch(
        'https://api.bunny.net/system/register-affiliate-click?refCode=' +
        encodeURIComponent(ref)
      )
      setCookie('AffiliateCode', ref, 30)
    }

    resolve()
  })

/**
 * Send tracking data to posthog
 * @returns a Promise
 */
const trackCampaignInfo = () =>
  new Promise<void>((resolve, reject) => {
    // capture as campaign event
    if (paramsToTrack['pk_campaign'] !== null) {
      /**
       * Properties that we want to capture in posthog
       * Consistently save them with a pk_ prefix.
       */
      const captureProperties = Object.entries(paramsToTrack).reduce(
        (acc, [key, value]) => {
          const keyWithPKPrefix = key.startsWith("pk") ? key : `pk_${key}`;
          return (acc[keyWithPKPrefix] = value, acc)
        }, {}
      )
      /**
       * Filter out defined (not null) capture properties
       */
      const definedCaptureProperties = {}
      Object.entries(captureProperties).forEach(([key, value]) => {
        if (value !== null) definedCaptureProperties[key] = value
      })
      /**
       * Capture a posthog event
       */
      PostHogHelper.capture('Campaign Info', definedCaptureProperties)
      /**
       * Save data to user instance
       */
      PostHogHelper.capture('Save User Campaign Info', {
        $set_once: definedCaptureProperties,
      })
    }

    resolve()
  })

const PostHogHelper = {
  analyse: async () => {
    PostHogHelper.capture('$pageview')
    // run the internal functions
    await extractURLParamsToTrack()
    await setRefAndAffiliateCookies()
    await trackCampaignInfo()
  },
  captureEmail: (email: string) => {
    PostHogHelper.capture('Identify by Form', {
      $set: {
        email,
      },
    })
  },
  capture: (event: string, properties?) => {
    if (typeof window !== 'undefined' && window.posthog) {
      if (properties) {
        window.posthog.capture(event, properties)
      } else {
        window.posthog.capture(event)
      }
    }
  },
  promiseToCaptureEmail: async (email: string): Promise<void> => {
    // This is what we want to do ...
    PostHogHelper.captureEmail(email)

    return new Promise((resolve) => {
      /**
       * There's an open issue about posthog.capture() not being a promise:
       * https://github.com/PostHog/posthog-js/issues/205
       *
       * We will fake a timeout in case a page redirect is about to happen.
       * Hopefully the capture event will happen faster than in 1 second.
       */
      setTimeout(() => {
        resolve()
      }, POSTHOG_REQUEST_MILLIS)
    })
  },
  /**
   * With enough testing abilities and confidence,
   * promiseToCaptureEmail should be replaced with requestToCapture
   */
  requestToCapture: async (properties, event: string) => {
    // Will send a network request to capture posthog event
    const timestamp = Date.now()
    const postData = await blindPostRequest(process.env.GATSBY_POSTHOG_API_HOST, {
      api_key: process.env.GATSBY_POSTHOG_API_KEY,
      properties,
      timestamp,
      event,
    })

    return postData
  },

  requestToCaptureEmail: async (email: string) => {
    return PostHogHelper.requestToCapture(
      {
        email,
      },
      'Identify by Form'
    )
  },
}

export const constructPostHogScriptBody = () => `
!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
posthog.init('${process.env.GATSBY_POSTHOG_API_KEY}', ${JSON.stringify({
  api_host: process.env.GATSBY_POSTHOG_API_HOST
})})
`

export default PostHogHelper
